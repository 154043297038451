export const environment = {
    "production": false,
    "serviceName": "CoverPortal",
    "env": "test",
    "amplify": {
        "Auth": {
            "mandatorySignIn": true,
            "region": "us-west-2",
            "userPoolId": "us-west-2_YCfLAuwe0",
            "identityPoolId": "",
            "userPoolWebClientId": "5ghpe5fd1qn7etn526dre2unci",
            "cookieStorage": {
                "domain": ".patest.studio",
                "secure": true,
                "path": "/",
                "expires": 7,
                "sameSite": "lax"
            },
            "oauth": {
                "domain": "auth.patest.studio",
                "redirectSignIn": "https://cover.patest.studio/",
                "redirectSignOut": "https://cover.patest.studio/",
                "responseType": "code"
            }
        }
    },
    "apiBaseUrls": {
        "cmf": "https://cmf.patest.studio/api",
        "rmf": "https://rmf.patest.studio/api",
        "idf": "https://idf.patest.studio/api",
        "pay": "https://pay.patest.studio/api",
        "loginPortal": "https://login.patest.studio"
    }
}